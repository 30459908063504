import React from 'react'

export default function ReportPagination(props) {
  return (
    <div className='reportContentPaginationContainer group'>
      <div
        className='reportContentPaginationArrow'
        onClick={() =>
          props.responseLength < 10 ? undefined : props.changePagination(10)
        }
      >
        <svg
          className={
            props.responseLength < 10
              ? 'reportArrowInActive'
              : 'reportArrowActive'
          }
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z' />
          <path fill='none' d='M0 0h24v24H0V0z' />
        </svg>
      </div>
      <div
        className='reportContentPaginationArrow'
        onClick={() =>
          props.index === 0 ? undefined : props.changePagination(-10)
        }
      >
        <svg
          className={
            props.index === 0 ? 'reportArrowInActive' : 'reportArrowActive'
          }
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path d='M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z' />
          <path fill='none' d='M0 0h24v24H0V0z' />
        </svg>
      </div>
      <span className='reportContentPaginationNumber'>
        {props.index}-{props.index + props.responseLength}
      </span>
    </div>
  )
}
