import React from 'react'

export default function ReportHeader(props) {
  let websiteAddress = props.reportName
  if (websiteAddress.slice(0, 10) === 'sc-domain:') {
    websiteAddress = websiteAddress.slice(10)
  }
  return (
    <div className='reportHeader'>
      <h1 className='reportHeaderFont'>SEO Report for</h1>
      <h1 className='reportHeaderFont'>{websiteAddress}</h1>
      <p className='reportHeaderDate'>
        {props.reportStartDate} - {props.reportEndDate}
      </p>
      {props.reportBranding.brandType === 'name' && (
        <h1 className='reportHeaderCompanyName'>
          {props.reportBranding.brandReference}
        </h1>
      )}
      {props.reportBranding.brandType === 'logo' && (
        <img
          width='250'
          height='150'
          className='reportHeaderLogo'
          src={props.reportBranding.brandReference}
          alt='Logo'
        />
      )}
    </div>
  )
}
