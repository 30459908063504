import React, { useState } from 'react'
import ReportPagination from './ReportPagination.js'
import SvgSpinner from '../Svg/SvgSpinner'
import appEnv from '../../appenv'

export default function ReportPageResult(props) {
  const [response, setResponse] = useState(props.gscWebPage)
  const [responseIndex, setResponseIndex] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const [hasRequestError, setHasRequestError] = useState(false)

  const changePagination = (indexChange) => {
    const indexRequestNumber = Number(responseIndex) + Number(indexChange)
    const reportIds = window.location.pathname.split('/')
    setIsFetching(true)
    let reportDetailUrl =
      appEnv.apiHostname +
      '/v1/public/seo/report/' +
      reportIds[3] +
      '/' +
      reportIds[4] +
      '/pages/' +
      props.paginationRoute +
      '?index=' +
      indexRequestNumber
    fetch(reportDetailUrl, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET',
      mode: 'cors'
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            response.json().then((jsonResponse) => {
              if (jsonResponse.data) {
                setResponse(jsonResponse.data)
                setResponseIndex(indexRequestNumber)
                setIsFetching(false)
                setHasRequestError(false)
              } else {
                setHasRequestError(true)
                setIsFetching(false)
              }
            })
            break
          case 400:
            setHasRequestError(true)
            setIsFetching(false)
            break
          case 500:
            setHasRequestError(true)
            setIsFetching(false)
            break
          default:
            setHasRequestError(true)
            setIsFetching(false)
        }
      })
      .catch((err) => {
        setHasRequestError(true)
        setIsFetching(false)
      })
  }

  return (
    <div>
      <div className='reportContentBoxLarge'>
        <h3 className='reportContentBoxHeading'>{props.title}</h3>
        <div className='reportTable'>
          <div className='reportTableRowHead group'>
            <div className='reportTableRowLeft'>Page Path</div>
            <div className='reportTableRowRight'>Difference</div>
            <div className='reportTableRowRight'>Impressions</div>
            <div className='reportTableRowRight'>Difference</div>
            <div className='reportTableRowRight'>Clicks</div>
          </div>
          {isFetching ? (
            <div className='reportTableFetching'>
              <SvgSpinner />
            </div>
          ) : response.length && !hasRequestError ? (
            response.map((page) => {
              return (
                <div className='reportTableRow group' key={page.pagePath}>
                  <div className='reportTableRowLeft'>{page.pagePath}</div>
                  <div className='reportTableRowRight'>
                    {page.impressionDifference > 0 && '+'}
                    {page.impressionDifference === 0
                      ? '-'
                      : page.impressionDifference}
                  </div>
                  <div className='reportTableRowRight'>{page.impressions}</div>
                  <div className='reportTableRowRight'>
                    {page.clickDifference > 0 && '+'}
                    {page.clickDifference === 0 ? '-' : page.clickDifference}
                  </div>
                  <div className='reportTableRowRight'>{page.clicks}</div>
                </div>
              )
            })
          ) : (
            <div className='reportTableRow group'>
              <div className='reportTableRowLeft'>No New Results.</div>
            </div>
          )}
        </div>
        <ReportPagination
          index={responseIndex}
          responseLength={response.length}
          changePagination={changePagination}
        />
      </div>
    </div>
  )
}
