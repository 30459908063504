import React from 'react'

export default function ReportOverview(props) {
  return (
    <div>
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Comments</h2>
      </div>
      <p className='reportFeedbackText'>{props.reportOverview}</p>
    </div>
  )
}
