import React from 'react'
import { LineChart, Line, Tooltip, XAxis } from 'recharts'

export default function ReportSeoStats(props) {
  let totalClicks = 0
  let totalClickChange = 0
  let totalImpressions = 0
  let totalImpressionChange = 0
  let totalKeywordsOneToThree = 0
  let totalKeywordsOneToThreeChange = 0
  let totalKeywordsOneToTen = 0
  let totalKeywordsOneToTenChange = 0
  let totalKeywordsIndexed = 0
  let totalKeywordsIndexedChange = 0
  let totalPagesIndexed = 0
  let totalPagesIndexedChange = 0
  let totalBacklinks = 0
  let totalBacklinksChange = 0

  const clickAndImpressionChartData = []
  const seoStatChartData = []
  const backlinkChartData = []

  function calculatePercentageChange(comparisonNumber, recentNumber) {
    let firstNumber = recentNumber - comparisonNumber
    let result = firstNumber / comparisonNumber
    if (isNaN(result)) {
      result = 0
      return result.toFixed(1)
    } else {
      return parseFloat((result * 100).toFixed(1))
    }
  }

  const returnFormattedDateString = function (dateString) {
    let splitDate = dateString.split('-')
    let month = ''
    switch (splitDate[1]) {
      case '01':
        month = 'Jan'
        break
      case '02':
        month = 'Feb'
        break
      case '03':
        month = 'Mar'
        break
      case '04':
        month = 'Apr'
        break
      case '05':
        month = 'May'
        break
      case '06':
        month = 'Jun'
        break
      case '07':
        month = 'Jul'
        break
      case '08':
        month = 'Aug'
        break
      case '09':
        month = 'Sep'
        break
      case '10':
        month = 'Oct'
        break
      case '11':
        month = 'Nov'
        break
      case '12':
        month = 'Dec'
        break
      default:
        month = 'Unk'
    }
    return month + ' ' + splitDate[2]
  }

  for (let x = 0; x < props.clickAndImpressionData.length; x++) {
    totalClicks +=
      props.clickAndImpressionData[x].desktopClicks +
      props.clickAndImpressionData[x].mobileClicks +
      props.clickAndImpressionData[x].tabletClicks
    totalImpressions +=
      props.clickAndImpressionData[x].desktopImpressions +
      props.clickAndImpressionData[x].mobileImpressions +
      props.clickAndImpressionData[x].tabletImpressions
    const formattedDate = returnFormattedDateString(
      props.clickAndImpressionData[x].day
    )
    clickAndImpressionChartData.push({
      date: formattedDate,
      clicks:
        props.clickAndImpressionData[x].desktopClicks +
        props.clickAndImpressionData[x].mobileClicks +
        props.clickAndImpressionData[x].tabletClicks,
      impressions:
        props.clickAndImpressionData[x].desktopImpressions +
        props.clickAndImpressionData[x].mobileImpressions +
        props.clickAndImpressionData[x].tabletImpressions
    })
  }

  for (let y = 0; y < props.seoStatData.length; y++) {
    const formattedDate = returnFormattedDateString(props.seoStatData[y].day)
    totalKeywordsOneToThree += props.seoStatData[y].keywordPositionsOneToThree
    totalKeywordsOneToTen += props.seoStatData[y].keywordPositionsOneToTen
    totalKeywordsIndexed = props.seoStatData[y].keywordsIndexed
    totalPagesIndexed = props.seoStatData[y].pagesIndexed
    seoStatChartData.push({
      date: formattedDate,
      keywordPositionsOneToThree:
        props.seoStatData[y].keywordPositionsOneToThree,
      keywordPositionsOneToTen: props.seoStatData[y].keywordPositionsOneToTen,
      keywordsIndexed: props.seoStatData[y].keywordsIndexed,
      pagesIndexed: props.seoStatData[y].pagesIndexed
    })
  }

  for (let z = 0; z < props.backlinkData.length; z++) {
    const formattedDate = returnFormattedDateString(props.backlinkData[z].day)
    totalBacklinks += props.backlinkData[z].count
    backlinkChartData.push({
      date: formattedDate,
      backlinks: props.backlinkData[z].count
    })
  }

  totalClickChange = calculatePercentageChange(
    props.clickAndImpressionDataComparison.clickTotal,
    totalClicks
  )

  totalImpressionChange = calculatePercentageChange(
    props.clickAndImpressionDataComparison.impressionTotal,
    totalImpressions
  )

  totalKeywordsOneToThreeChange = calculatePercentageChange(
    props.seoStatDataComparison.keywordPositionsOneToThreeTotal,
    totalKeywordsOneToThree
  )

  totalKeywordsOneToTenChange = calculatePercentageChange(
    props.seoStatDataComparison.keywordPositionsOneToTenTotal,
    totalKeywordsOneToTen
  )

  totalKeywordsIndexedChange = calculatePercentageChange(
    props.seoStatIndexedComparison.keywordsIndexedTotal,
    totalKeywordsIndexed
  )

  totalPagesIndexedChange = calculatePercentageChange(
    props.seoStatIndexedComparison.pagesIndexedTotal,
    totalPagesIndexed
  )

  totalBacklinksChange = calculatePercentageChange(
    props.backlinkDataComparison.backlinksTotal,
    totalBacklinks
  )

  return (
    <div className='group'>
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Website Metrics</h2>
      </div>
      <div className='reportContentBoxSmall'>
        <h3 className='reportContentBoxHeading'>Ranking 1-3</h3>
        <div className='reportContentBoxMain'>
          <span className='reportContentHighlightNumber'>
            {totalKeywordsOneToThree}
          </span>
          <span className='reportContentHighlightComparison'>
            {totalKeywordsOneToThreeChange < 0 ? (
              <span className='reportContentHighlightNegative'>
                {totalKeywordsOneToThreeChange}%
              </span>
            ) : (
              <span className='reportContentHighlightPositive'>
                {totalKeywordsOneToThreeChange}%
              </span>
            )}{' '}
            vs prev {props.comparisonDays} days
          </span>
          <div className='reportContentHighlightChart'>
            <LineChart
              width={238}
              height={58}
              data={seoStatChartData}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey='date' hide={true} />
              <Line
                name='Keywords Ranked'
                type='monotone'
                dataKey='keywordPositionsOneToThree'
                stroke='#4285f4'
                strokeWidth={2}
                dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip />
            </LineChart>
          </div>
        </div>
      </div>
      <div className='reportContentBoxSmallMiddle'>
        <h3 className='reportContentBoxHeading'>Ranking 1-10</h3>
        <div className='reportContentBoxMain'>
          <span className='reportContentHighlightNumber'>
            {totalKeywordsOneToTen}
          </span>
          <span className='reportContentHighlightComparison'>
            {totalKeywordsOneToTenChange < 0 ? (
              <span className='reportContentHighlightNegative'>
                {totalKeywordsOneToTenChange}%
              </span>
            ) : (
              <span className='reportContentHighlightPositive'>
                {totalKeywordsOneToTenChange}%
              </span>
            )}{' '}
            vs prev {props.comparisonDays} days
          </span>
          <div className='reportContentHighlightChart'>
            <LineChart
              width={238}
              height={58}
              data={seoStatChartData}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey='date' hide={true} />
              <Line
                name='Keywords Ranked'
                type='monotone'
                dataKey='keywordPositionsOneToTen'
                stroke='#4285f4'
                strokeWidth={2}
                dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip />
            </LineChart>
          </div>
        </div>
      </div>
      <div className='reportContentBoxSmall'>
        <h3 className='reportContentBoxHeading'>Clicks</h3>
        <div className='reportContentBoxMain'>
          <span className='reportContentHighlightNumber'>{totalClicks}</span>
          <span className='reportContentHighlightComparison'>
            {totalClickChange < 0 ? (
              <span className='reportContentHighlightNegative'>
                {totalClickChange}%
              </span>
            ) : (
              <span className='reportContentHighlightPositive'>
                {totalClickChange}%
              </span>
            )}{' '}
            vs prev {props.comparisonDays} days
          </span>
          <div className='reportContentHighlightChart'>
            <LineChart
              width={238}
              height={58}
              data={clickAndImpressionChartData}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey='date' hide={true} />
              <Line
                name='Clicks'
                type='monotone'
                dataKey='clicks'
                stroke='#4285f4'
                strokeWidth={2}
                dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip />
            </LineChart>
          </div>
        </div>
      </div>
      <div className='reportContentBoxSmall'>
        <h3 className='reportContentBoxHeading'>Impressions</h3>
        <div className='reportContentBoxMain'>
          <span className='reportContentHighlightNumber'>
            {totalImpressions}
          </span>
          <span className='reportContentHighlightComparison'>
            {totalImpressionChange < 0 ? (
              <span className='reportContentHighlightNegative'>
                {totalImpressionChange}%
              </span>
            ) : (
              <span className='reportContentHighlightPositive'>
                {totalImpressionChange}%
              </span>
            )}{' '}
            vs prev {props.comparisonDays} days
          </span>
          <div className='reportContentHighlightChart'>
            <LineChart
              width={238}
              height={58}
              data={clickAndImpressionChartData}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey='date' hide={true} />
              <Line
                name='Impressions'
                type='monotone'
                dataKey='impressions'
                stroke='#4285f4'
                strokeWidth={2}
                dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip />
            </LineChart>
          </div>
        </div>
      </div>
      <div className='reportContentBoxSmallMiddle'>
        <h3 className='reportContentBoxHeading'>Keywords Indexed</h3>
        <div className='reportContentBoxMain'>
          <span className='reportContentHighlightNumber'>
            {totalKeywordsIndexed}
          </span>
          <span className='reportContentHighlightComparison'>
            {totalKeywordsIndexedChange < 0 ? (
              <span className='reportContentHighlightNegative'>
                {totalKeywordsIndexedChange}%
              </span>
            ) : (
              <span className='reportContentHighlightPositive'>
                {totalKeywordsIndexedChange}%
              </span>
            )}{' '}
            vs prev {props.comparisonDays} days
          </span>
          <div className='reportContentHighlightChart'>
            <LineChart
              width={238}
              height={58}
              data={seoStatChartData}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey='date' hide={true} />
              <Line
                name='Keywords Indexed'
                type='monotone'
                dataKey='keywordsIndexed'
                stroke='#4285f4'
                strokeWidth={2}
                dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip />
            </LineChart>
          </div>
        </div>
      </div>
      <div className='reportContentBoxSmall'>
        <h3 className='reportContentBoxHeading'>Pages Indexed</h3>
        <div className='reportContentBoxMain'>
          <span className='reportContentHighlightNumber'>
            {totalPagesIndexed}
          </span>
          <span className='reportContentHighlightComparison'>
            {totalPagesIndexedChange < 0 ? (
              <span className='reportContentHighlightNegative'>
                {totalPagesIndexedChange}%
              </span>
            ) : (
              <span className='reportContentHighlightPositive'>
                {totalPagesIndexedChange}%
              </span>
            )}{' '}
            vs prev {props.comparisonDays} days
          </span>
          <div className='reportContentHighlightChart'>
            <LineChart
              width={238}
              height={58}
              data={seoStatChartData}
              margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis dataKey='date' hide={true} />
              <Line
                name='Pages Indexed'
                type='monotone'
                dataKey='pagesIndexed'
                stroke='#4285f4'
                strokeWidth={2}
                dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip />
            </LineChart>
          </div>
        </div>
      </div>
      {props.gaImportStartDate && (
        <div className='reportContentBoxSmall'>
          <h3 className='reportContentBoxHeading'>New Backlinks</h3>
          <div className='reportContentBoxMain'>
            <span className='reportContentHighlightNumber'>
              {totalBacklinks}
            </span>
            <span className='reportContentHighlightComparison'>
              {totalBacklinksChange < 0 ? (
                <span className='reportContentHighlightNegative'>
                  {totalBacklinksChange}%
                </span>
              ) : (
                <span className='reportContentHighlightPositive'>
                  {totalBacklinksChange}%
                </span>
              )}{' '}
              vs prev {props.comparisonDays} days
            </span>
            <div className='reportContentHighlightChart'>
              <LineChart
                width={238}
                height={58}
                data={backlinkChartData}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              >
                <XAxis dataKey='date' hide={true} />
                <Line
                  name='New Backlinks'
                  type='monotone'
                  dataKey='backlinks'
                  stroke='#4285f4'
                  strokeWidth={2}
                  dot={{ stroke: '#4285f4', strokeWidth: 1, r: 2 }}
                  isAnimationActive={false}
                />
                <Tooltip />
              </LineChart>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
