import React from 'react'
import SvgSpinner from '../Svg/SvgSpinner'

export default function ReportLoading(props) {
  return (
    <div className='reportLoading'>
      <SvgSpinner />
    </div>
  )
}
