import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import countryList from '../Common/CountryList'

export default function ReportTrafficSources(props) {
  const topTrafficSources = []
  for (let x = 0; x < props.trafficSources.length; x++) {
    let source = props.trafficSources[x].source
    let sourceSplit = source.split(' ')
    for (let y = 0; y < countryList.data.length; y++) {
      if (countryList.data[y].alpha3 === sourceSplit[0]) {
        console.log(countryList.data[y].alpha3)
        topTrafficSources.push({
          source: countryList.data[y].name + ' ' + sourceSplit[1],
          clicks: props.trafficSources[x].clicks,
          impressions: props.trafficSources[x].impressions
        })
      }
    }
  }

  return (
    <div>
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Traffic Sources</h2>
      </div>
      <div className='reportContentBoxLarge'>
        <h3 className='reportContentBoxHeading'>Top 5 Demographics</h3>
        <div className='reportContentClickMetricBox'>
          <ResponsiveContainer width='100%' height={400}>
            <BarChart
              layout='vertical'
              data={topTrafficSources}
              margin={{
                top: 5,
                right: 30,
                left: 90,
                bottom: 5
              }}
            >
              <XAxis type='number' />
              <YAxis
                type='category'
                dataKey='source'
                tick={{ fontSize: 12, width: 150 }}
                height={250}
              />
              <Tooltip />
              <Legend />
              <Bar
                name='Impressions'
                dataKey='impressions'
                stackId='a'
                fill='#0176FF'
              />
              <Bar name='Clicks' dataKey='clicks' stackId='a' fill='#550CD5' />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
