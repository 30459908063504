import 'react-app-polyfill/ie9' // For IE 9-11 support
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './assets/css/report.css'
import Report from './components/Report/Report'

Sentry.init({
  dsn: 'https://30779892bf7b49a4870c17196e2699ca@sentry.io/1835757'
})

ReactDOM.render(<Report />, document.getElementById('root'))
