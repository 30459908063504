import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

export default function ReportImpressionMetrics(props) {
  const returnFormattedDateString = function (dateString) {
    let splitDate = dateString.split('-')
    let month = ''
    switch (splitDate[1]) {
      case '01':
        month = 'Jan'
        break
      case '02':
        month = 'Feb'
        break
      case '03':
        month = 'Mar'
        break
      case '04':
        month = 'Apr'
        break
      case '05':
        month = 'May'
        break
      case '06':
        month = 'Jun'
        break
      case '07':
        month = 'Jul'
        break
      case '08':
        month = 'Aug'
        break
      case '09':
        month = 'Sep'
        break
      case '10':
        month = 'Oct'
        break
      case '11':
        month = 'Nov'
        break
      case '12':
        month = 'Dec'
        break
      default:
        month = 'Unk'
    }
    return month + ' ' + splitDate[2]
  }

  const formattedClickMetrics = []
  let totalDesktopImpressions = 0
  let totalMobileImpressions = 0
  let totalTabletImpressions = 0
  for (let x = 0; x < props.clickAndImpressionData.length; x++) {
    const formattedDate = returnFormattedDateString(
      props.clickAndImpressionData[x].day
    )
    formattedClickMetrics.push({
      date: formattedDate,
      desktopImpressions: props.clickAndImpressionData[x].desktopImpressions,
      mobileImpressions: props.clickAndImpressionData[x].mobileImpressions,
      tabletImpressions: props.clickAndImpressionData[x].tabletImpressions
    })
    totalDesktopImpressions +=
      props.clickAndImpressionData[x].desktopImpressions
    totalMobileImpressions += props.clickAndImpressionData[x].mobileImpressions
    totalTabletImpressions += props.clickAndImpressionData[x].tabletImpressions
  }

  return (
    <div>
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Impression Metrics</h2>
      </div>
      <div className='reportContentBoxLarge'>
        <h3 className='reportContentBoxHeading'>
          {props.comparisonDays} Day Impression Metrics
        </h3>
        <div className='reportContentClickMetricBox'>
          <div className='reportContentClickMetricStatsBox'>
            <span className='reportContentHighlightHeading desktopColor'>
              Desktop Impressions
            </span>
            <span className='reportContentHighlightNumber'>
              {totalDesktopImpressions.toLocaleString()}
            </span>
          </div>
          <div className='reportContentClickMetricStatsBox'>
            <span className='reportContentHighlightHeading mobileColor'>
              Mobile Impressions
            </span>
            <span className='reportContentHighlightNumber'>
              {totalMobileImpressions.toLocaleString()}
            </span>
          </div>
          <div className='reportContentClickMetricStatsBox'>
            <span className='reportContentHighlightHeading tabletColor'>
              Tablet Impressions
            </span>
            <span className='reportContentHighlightNumber'>
              {totalTabletImpressions.toLocaleString()}
            </span>
          </div>

          <ResponsiveContainer width='100%' height={200}>
            <LineChart width={500} height={200} data={formattedClickMetrics}>
              <Line
                name='Desktop Impressions'
                type='monotone'
                dataKey='desktopImpressions'
                stroke='#0176FF'
                strokeWidth={2}
              />
              <Line
                name='Tablet Impressions'
                type='monotone'
                dataKey='tabletImpressions'
                stroke='#01D5AA'
                strokeWidth={2}
              />
              <Line
                name='Mobile Impressions'
                type='monotone'
                dataKey='mobileImpressions'
                stroke='#550CD5'
                strokeWidth={2}
              />
              <CartesianGrid stroke='#ccc' />
              <XAxis dataKey='date' />
              <YAxis
                datakey={
                  totalDesktopImpressions > totalMobileImpressions
                    ? '"desktopImpressions"'
                    : '"mobileImpressions"'
                }
              />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
