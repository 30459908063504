import React from 'react'

export default function SvgReport(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <g transform='matrix(4.166666666666667,0,0,4.166666666666667,0,0)'>
        <path d='M2.939,24h18.122c1.622-0.002,2.937-1.317,2.939-2.939V2.939C23.998,1.317,22.683,0.002,21.061,0H2.939 C1.317,0.002,0.002,1.317,0,2.939v18.122C0.002,22.683,1.317,23.998,2.939,24z M21.061,22.041H2.939c-0.541,0-0.98-0.439-0.98-0.98 V3c0-0.552,0.448-1,1-1h18.082c0.552,0,1,0.448,1,1v18.061C22.041,21.602,21.602,22.041,21.061,22.041z M18.836,15.592 l-2.064,1.832c-0.089,0.078-0.22,0.084-0.315,0.014l-1.366-1.012c-0.332-0.232-0.73-0.351-1.135-0.339 c-0.412,0.009-0.808,0.161-1.119,0.431l-2.517,2.234c-0.414,0.367-0.453,1-0.086,1.414c0.367,0.414,1,0.453,1.414,0.086 l2.233-1.982c0.088-0.078,0.219-0.084,0.314-0.014l1.366,1.012c0.332,0.233,0.73,0.352,1.135,0.34 c0.412-0.009,0.808-0.162,1.119-0.432l2.349-2.084c0.414-0.367,0.453-1,0.086-1.414S19.25,15.225,18.836,15.592z M14.331,10.123 c-0.036-0.051-0.093-0.083-0.155-0.087L10.11,9.722c-0.116-0.01-0.217,0.076-0.227,0.192c-0.002,0.022,0,0.044,0.005,0.065 l0.884,3.981c0.014,0.06,0.053,0.112,0.108,0.141c0.055,0.029,0.12,0.032,0.177,0.009c1.654-0.66,2.886-2.08,3.308-3.81 C14.381,10.239,14.369,10.174,14.331,10.123z M7.209,3.809C7.099,3.848,7.041,3.969,7.08,4.08C7.084,4.09,7.088,4.1,7.093,4.109 L9.359,8.3c0.034,0.063,0.098,0.104,0.169,0.109l4.744,0.367c0.116,0.009,0.218-0.077,0.227-0.193c0.001-0.012,0.001-0.024,0-0.036 c-0.255-3.027-2.915-5.274-5.942-5.02c-0.46,0.039-0.913,0.135-1.348,0.287L7.209,3.809z M5.836,4.608 C5.775,4.595,5.712,4.609,5.663,4.647c-2.404,1.857-2.848,5.311-0.991,7.715c1.04,1.347,2.646,2.137,4.348,2.138 c0.107,0,0.213,0,0.319-0.009c0.062-0.003,0.119-0.034,0.156-0.084c0.037-0.049,0.051-0.113,0.038-0.173L8.426,9.253 c-0.004-0.019-0.011-0.038-0.02-0.055L5.978,4.714C5.949,4.66,5.897,4.621,5.836,4.608z M18.5,4.5c0.552,0,1,0.448,1,1 s-0.448,1-1,1s-1-0.448-1-1S17.948,4.5,18.5,4.5z M18.5,7.5c0.552,0,1,0.448,1,1s-0.448,1-1,1s-1-0.448-1-1S17.948,7.5,18.5,7.5z M18.5,10.5c0.552,0,1,0.448,1,1s-0.448,1-1,1s-1-0.448-1-1S17.948,10.5,18.5,10.5z'></path>
      </g>
    </svg>
  )
}
