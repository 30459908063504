import React from 'react'
import SvgReport from '../Svg/SvgReport'

export default function ReportError(props) {
  return (
    <div className='noMatch'>
      <div className='noMatchContainer'>
        <div className='noMatchContainerHeading'>
          <SvgReport />
          <span>Report missing</span>
        </div>
        <div className='noMatchContainerMessage'>
          <p>Oops! Looks like an error occured or this report is missing.</p>
          <p>Please contact your representitive for help.</p>
        </div>
      </div>
    </div>
  )
}
