import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

export default function ReportClickMetrics(props) {
  const returnFormattedDateString = function (dateString) {
    let splitDate = dateString.split('-')
    let month = ''
    switch (splitDate[1]) {
      case '01':
        month = 'Jan'
        break
      case '02':
        month = 'Feb'
        break
      case '03':
        month = 'Mar'
        break
      case '04':
        month = 'Apr'
        break
      case '05':
        month = 'May'
        break
      case '06':
        month = 'Jun'
        break
      case '07':
        month = 'Jul'
        break
      case '08':
        month = 'Aug'
        break
      case '09':
        month = 'Sep'
        break
      case '10':
        month = 'Oct'
        break
      case '11':
        month = 'Nov'
        break
      case '12':
        month = 'Dec'
        break
      default:
        month = 'Unk'
    }
    return month + ' ' + splitDate[2]
  }

  const formattedClickMetrics = []
  let totalDesktopClicks = 0
  let totalMobileClicks = 0
  let totalTabletClicks = 0
  for (let x = 0; x < props.clickAndImpressionData.length; x++) {
    const formattedDate = returnFormattedDateString(
      props.clickAndImpressionData[x].day
    )
    formattedClickMetrics.push({
      date: formattedDate,
      desktopClicks: props.clickAndImpressionData[x].desktopClicks,
      mobileClicks: props.clickAndImpressionData[x].mobileClicks,
      tabletClicks: props.clickAndImpressionData[x].tabletClicks
    })
    totalDesktopClicks += props.clickAndImpressionData[x].desktopClicks
    totalMobileClicks += props.clickAndImpressionData[x].mobileClicks
    totalTabletClicks += props.clickAndImpressionData[x].tabletClicks
  }

  return (
    <div>
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Click Metrics</h2>
      </div>
      <div className='reportContentBoxLarge'>
        <h3 className='reportContentBoxHeading'>
          {props.comparisonDays} Day Click Metrics
        </h3>
        <div className='reportContentClickMetricBox'>
          <div className='reportContentClickMetricStatsBox'>
            <span className='reportContentHighlightHeading desktopColor'>
              Desktop Clicks
            </span>
            <span className='reportContentHighlightNumber'>
              {totalDesktopClicks.toLocaleString()}
            </span>
          </div>
          <div className='reportContentClickMetricStatsBox'>
            <span className='reportContentHighlightHeading mobileColor'>
              Mobile Clicks
            </span>
            <span className='reportContentHighlightNumber'>
              {totalMobileClicks.toLocaleString()}
            </span>
          </div>
          <div className='reportContentClickMetricStatsBox'>
            <span className='reportContentHighlightHeading tabletColor'>
              Tablet Clicks
            </span>
            <span className='reportContentHighlightNumber'>
              {totalTabletClicks.toLocaleString()}
            </span>
          </div>

          <ResponsiveContainer width='100%' height={200}>
            <LineChart width={500} height={200} data={formattedClickMetrics}>
              <Line
                name='Desktop Clicks'
                type='monotone'
                dataKey='desktopClicks'
                stroke='#0176FF'
                strokeWidth={2}
              />
              <Line
                name='Tablet Clicks'
                type='monotone'
                dataKey='tabletClicks'
                stroke='#01D5AA'
                strokeWidth={2}
              />
              <Line
                name='Mobile Clicks'
                type='monotone'
                dataKey='mobileClicks'
                stroke='#550CD5'
                strokeWidth={2}
              />
              <CartesianGrid stroke='#ccc' />
              <XAxis dataKey='date' />
              <YAxis
                datakey={
                  totalDesktopClicks > totalMobileClicks
                    ? '"desktopClicks"'
                    : '"mobileClicks"'
                }
              />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
