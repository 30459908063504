export default {
  data: [
    { name: 'Afghanistan', alpha3: 'afg', id: '004' },
    { name: 'Åland Islands', alpha3: 'ala', id: '248' },
    { name: 'Albania', alpha3: 'alb', id: '008' },
    { name: 'Algeria', alpha3: 'dza', id: '012' },
    { name: 'American Samoa', alpha3: 'asm', id: '016' },
    { name: 'Andorra', alpha3: 'and', id: '020' },
    { name: 'Angola', alpha3: 'ago', id: '024' },
    { name: 'Anguilla', alpha3: 'aia', id: '660' },
    { name: 'Antarctica', alpha3: 'ata', id: '010' },
    { name: 'Antigua and Barbuda', alpha3: 'atg', id: '028' },
    { name: 'Argentina', alpha3: 'arg', id: '032' },
    { name: 'Armenia', alpha3: 'arm', id: '051' },
    { name: 'Aruba', alpha3: 'abw', id: '533' },
    { name: 'Australia', alpha3: 'aus', id: '036' },
    { name: 'Austria', alpha3: 'aut', id: '040' },
    { name: 'Azerbaijan', alpha3: 'aze', id: '031' },
    { name: 'Bahamas', alpha3: 'bhs', id: '044' },
    { name: 'Bahrain', alpha3: 'bhr', id: '048' },
    { name: 'Bangladesh', alpha3: 'bgd', id: '050' },
    { name: 'Barbados', alpha3: 'brb', id: '052' },
    { name: 'Belarus', alpha3: 'blr', id: '112' },
    { name: 'Belgium', alpha3: 'bel', id: '056' },
    { name: 'Belize', alpha3: 'blz', id: '084' },
    { name: 'Benin', alpha3: 'ben', id: '204' },
    { name: 'Bermuda', alpha3: 'bmu', id: '060' },
    { name: 'Bhutan', alpha3: 'btn', id: '064' },
    { name: 'Bolivia (Plurinational State of)', alpha3: 'bol', id: '068' },
    { name: 'Bonaire, Sint Eustatius and Saba', alpha3: 'bes', id: '535' },
    { name: 'Bosnia and Herzegovina', alpha3: 'bih', id: '070' },
    { name: 'Botswana', alpha3: 'bwa', id: '072' },
    { name: 'Bouvet Island', alpha3: 'bvt', id: '074' },
    { name: 'Brazil', alpha3: 'bra', id: '076' },
    { name: 'British Indian Ocean Territory', alpha3: 'iot', id: '086' },
    { name: 'Brunei Darussalam', alpha3: 'brn', id: '096' },
    { name: 'Bulgaria', alpha3: 'bgr', id: '100' },
    { name: 'Burkina Faso', alpha3: 'bfa', id: '854' },
    { name: 'Burundi', alpha3: 'bdi', id: '108' },
    { name: 'Cabo Verde', alpha3: 'cpv', id: '132' },
    { name: 'Cambodia', alpha3: 'khm', id: '116' },
    { name: 'Cameroon', alpha3: 'cmr', id: '120' },
    { name: 'Canada', alpha3: 'can', id: '124' },
    { name: 'Cayman Islands', alpha3: 'cym', id: '136' },
    { name: 'Central African Republic', alpha3: 'caf', id: '140' },
    { name: 'Chad', alpha3: 'tcd', id: '148' },
    { name: 'Chile', alpha3: 'chl', id: '152' },
    { name: 'China', alpha3: 'chn', id: '156' },
    { name: 'Christmas Island', alpha3: 'cxr', id: '162' },
    { name: 'Cocos (Keeling) Islands', alpha3: 'cck', id: '166' },
    { name: 'Colombia', alpha3: 'col', id: '170' },
    { name: 'Comoros', alpha3: 'com', id: '174' },
    { name: 'Congo', alpha3: 'cog', id: '178' },
    { name: 'Congo, Democratic Republic of the', alpha3: 'cod', id: '180' },
    { name: 'Cook Islands', alpha3: 'cok', id: '184' },
    { name: 'Costa Rica', alpha3: 'cri', id: '188' },
    { name: "Côte d'Ivoire", alpha3: 'civ', id: '384' },
    { name: 'Croatia', alpha3: 'hrv', id: '191' },
    { name: 'Cuba', alpha3: 'cub', id: '192' },
    { name: 'Curaçao', alpha3: 'cuw', id: '531' },
    { name: 'Cyprus', alpha3: 'cyp', id: '196' },
    { name: 'Czechia', alpha3: 'cze', id: '203' },
    { name: 'Denmark', alpha3: 'dnk', id: '208' },
    { name: 'Djibouti', alpha3: 'dji', id: '262' },
    { name: 'Dominica', alpha3: 'dma', id: '212' },
    { name: 'Dominican Republic', alpha3: 'dom', id: '214' },
    { name: 'Ecuador', alpha3: 'ecu', id: '218' },
    { name: 'Egypt', alpha3: 'egy', id: '818' },
    { name: 'El Salvador', alpha3: 'slv', id: '222' },
    { name: 'Equatorial Guinea', alpha3: 'gnq', id: '226' },
    { name: 'Eritrea', alpha3: 'eri', id: '232' },
    { name: 'Estonia', alpha3: 'est', id: '233' },
    { name: 'Eswatini', alpha3: 'swz', id: '748' },
    { name: 'Ethiopia', alpha3: 'eth', id: '231' },
    { name: 'Falkland Islands (Malvinas)', alpha3: 'flk', id: '238' },
    { name: 'Faroe Islands', alpha3: 'fro', id: '234' },
    { name: 'Fiji', alpha3: 'fji', id: '242' },
    { name: 'Finland', alpha3: 'fin', id: '246' },
    { name: 'France', alpha3: 'fra', id: '250' },
    { name: 'French Guiana', alpha3: 'guf', id: '254' },
    { name: 'French Polynesia', alpha3: 'pyf', id: '258' },
    { name: 'French Southern Territories', alpha3: 'atf', id: '260' },
    { name: 'Gabon', alpha3: 'gab', id: '266' },
    { name: 'Gambia', alpha3: 'gmb', id: '270' },
    { name: 'Georgia', alpha3: 'geo', id: '268' },
    { name: 'Germany', alpha3: 'deu', id: '276' },
    { name: 'Ghana', alpha3: 'gha', id: '288' },
    { name: 'Gibraltar', alpha3: 'gib', id: '292' },
    { name: 'Greece', alpha3: 'grc', id: '300' },
    { name: 'Greenland', alpha3: 'grl', id: '304' },
    { name: 'Grenada', alpha3: 'grd', id: '308' },
    { name: 'Guadeloupe', alpha3: 'glp', id: '312' },
    { name: 'Guam', alpha3: 'gum', id: '316' },
    { name: 'Guatemala', alpha3: 'gtm', id: '320' },
    { name: 'Guernsey', alpha3: 'ggy', id: '831' },
    { name: 'Guinea', alpha3: 'gin', id: '324' },
    { name: 'Guinea-Bissau', alpha3: 'gnb', id: '624' },
    { name: 'Guyana', alpha3: 'guy', id: '328' },
    { name: 'Haiti', alpha3: 'hti', id: '332' },
    { name: 'Heard Island and McDonald Islands', alpha3: 'hmd', id: '334' },
    { name: 'Holy See', alpha3: 'vat', id: '336' },
    { name: 'Honduras', alpha3: 'hnd', id: '340' },
    { name: 'Hong Kong', alpha3: 'hkg', id: '344' },
    { name: 'Hungary', alpha3: 'hun', id: '348' },
    { name: 'Iceland', alpha3: 'isl', id: '352' },
    { name: 'India', alpha3: 'ind', id: '356' },
    { name: 'Indonesia', alpha3: 'idn', id: '360' },
    { name: 'Iran (Islamic Republic of)', alpha3: 'irn', id: '364' },
    { name: 'Iraq', alpha3: 'irq', id: '368' },
    { name: 'Ireland', alpha3: 'irl', id: '372' },
    { name: 'Isle of Man', alpha3: 'imn', id: '833' },
    { name: 'Israel', alpha3: 'isr', id: '376' },
    { name: 'Italy', alpha3: 'ita', id: '380' },
    { name: 'Jamaica', alpha3: 'jam', id: '388' },
    { name: 'Japan', alpha3: 'jpn', id: '392' },
    { name: 'Jersey', alpha3: 'jey', id: '832' },
    { name: 'Jordan', alpha3: 'jor', id: '400' },
    { name: 'Kazakhstan', alpha3: 'kaz', id: '398' },
    { name: 'Kenya', alpha3: 'ken', id: '404' },
    { name: 'Kiribati', alpha3: 'kir', id: '296' },
    {
      name: "Korea (Democratic People's Republic of)",
      alpha3: 'prk',
      id: '408'
    },
    { name: 'Korea, Republic of', alpha3: 'kor', id: '410' },
    { name: 'Kuwait', alpha3: 'kwt', id: '414' },
    { name: 'Kyrgyzstan', alpha3: 'kgz', id: '417' },
    { name: "Lao People's Democratic Republic", alpha3: 'lao', id: '418' },
    { name: 'Latvia', alpha3: 'lva', id: '428' },
    { name: 'Lebanon', alpha3: 'lbn', id: '422' },
    { name: 'Lesotho', alpha3: 'lso', id: '426' },
    { name: 'Liberia', alpha3: 'lbr', id: '430' },
    { name: 'Libya', alpha3: 'lby', id: '434' },
    { name: 'Liechtenstein', alpha3: 'lie', id: '438' },
    { name: 'Lithuania', alpha3: 'ltu', id: '440' },
    { name: 'Luxembourg', alpha3: 'lux', id: '442' },
    { name: 'Macao', alpha3: 'mac', id: '446' },
    { name: 'Madagascar', alpha3: 'mdg', id: '450' },
    { name: 'Malawi', alpha3: 'mwi', id: '454' },
    { name: 'Malaysia', alpha3: 'mys', id: '458' },
    { name: 'Maldives', alpha3: 'mdv', id: '462' },
    { name: 'Mali', alpha3: 'mli', id: '466' },
    { name: 'Malta', alpha3: 'mlt', id: '470' },
    { name: 'Marshall Islands', alpha3: 'mhl', id: '584' },
    { name: 'Martinique', alpha3: 'mtq', id: '474' },
    { name: 'Mauritania', alpha3: 'mrt', id: '478' },
    { name: 'Mauritius', alpha3: 'mus', id: '480' },
    { name: 'Mayotte', alpha3: 'myt', id: '175' },
    { name: 'Mexico', alpha3: 'mex', id: '484' },
    { name: 'Micronesia (Federated States of)', alpha3: 'fsm', id: '583' },
    { name: 'Moldova, Republic of', alpha3: 'mda', id: '498' },
    { name: 'Monaco', alpha3: 'mco', id: '492' },
    { name: 'Mongolia', alpha3: 'mng', id: '496' },
    { name: 'Montenegro', alpha3: 'mne', id: '499' },
    { name: 'Montserrat', alpha3: 'msr', id: '500' },
    { name: 'Morocco', alpha3: 'mar', id: '504' },
    { name: 'Mozambique', alpha3: 'moz', id: '508' },
    { name: 'Myanmar', alpha3: 'mmr', id: '104' },
    { name: 'Namibia', alpha3: 'nam', id: '516' },
    { name: 'Nauru', alpha3: 'nru', id: '520' },
    { name: 'Nepal', alpha3: 'npl', id: '524' },
    { name: 'Netherlands', alpha3: 'nld', id: '528' },
    { name: 'New Caledonia', alpha3: 'ncl', id: '540' },
    { name: 'New Zealand', alpha3: 'nzl', id: '554' },
    { name: 'Nicaragua', alpha3: 'nic', id: '558' },
    { name: 'Niger', alpha3: 'ner', id: '562' },
    { name: 'Nigeria', alpha3: 'nga', id: '566' },
    { name: 'Niue', alpha3: 'niu', id: '570' },
    { name: 'Norfolk Island', alpha3: 'nfk', id: '574' },
    { name: 'North Macedonia', alpha3: 'mkd', id: '807' },
    { name: 'Northern Mariana Islands', alpha3: 'mnp', id: '580' },
    { name: 'Norway', alpha3: 'nor', id: '578' },
    { name: 'Oman', alpha3: 'omn', id: '512' },
    { name: 'Pakistan', alpha3: 'pak', id: '586' },
    { name: 'Palau', alpha3: 'plw', id: '585' },
    { name: 'Palestine, State of', alpha3: 'pse', id: '275' },
    { name: 'Panama', alpha3: 'pan', id: '591' },
    { name: 'Papua New Guinea', alpha3: 'png', id: '598' },
    { name: 'Paraguay', alpha3: 'pry', id: '600' },
    { name: 'Peru', alpha3: 'per', id: '604' },
    { name: 'Philippines', alpha3: 'phl', id: '608' },
    { name: 'Pitcairn', alpha3: 'pcn', id: '612' },
    { name: 'Poland', alpha3: 'pol', id: '616' },
    { name: 'Portugal', alpha3: 'prt', id: '620' },
    { name: 'Puerto Rico', alpha3: 'pri', id: '630' },
    { name: 'Qatar', alpha3: 'qat', id: '634' },
    { name: 'Réunion', alpha3: 'reu', id: '638' },
    { name: 'Romania', alpha3: 'rou', id: '642' },
    { name: 'Russian Federation', alpha3: 'rus', id: '643' },
    { name: 'Rwanda', alpha3: 'rwa', id: '646' },
    { name: 'Saint Barthélemy', alpha3: 'blm', id: '652' },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      alpha3: 'shn',
      id: '654'
    },
    { name: 'Saint Kitts and Nevis', alpha3: 'kna', id: '659' },
    { name: 'Saint Lucia', alpha3: 'lca', id: '662' },
    { name: 'Saint Martin (French part)', alpha3: 'maf', id: '663' },
    { name: 'Saint Pierre and Miquelon', alpha3: 'spm', id: '666' },
    { name: 'Saint Vincent and the Grenadines', alpha3: 'vct', id: '670' },
    { name: 'Samoa', alpha3: 'wsm', id: '882' },
    { name: 'San Marino', alpha3: 'smr', id: '674' },
    { name: 'Sao Tome and Principe', alpha3: 'stp', id: '678' },
    { name: 'Saudi Arabia', alpha3: 'sau', id: '682' },
    { name: 'Senegal', alpha3: 'sen', id: '686' },
    { name: 'Serbia', alpha3: 'srb', id: '688' },
    { name: 'Seychelles', alpha3: 'syc', id: '690' },
    { name: 'Sierra Leone', alpha3: 'sle', id: '694' },
    { name: 'Singapore', alpha3: 'sgp', id: '702' },
    { name: 'Sint Maarten (Dutch part)', alpha3: 'sxm', id: '534' },
    { name: 'Slovakia', alpha3: 'svk', id: '703' },
    { name: 'Slovenia', alpha3: 'svn', id: '705' },
    { name: 'Solomon Islands', alpha3: 'slb', id: '090' },
    { name: 'Somalia', alpha3: 'som', id: '706' },
    { name: 'South Africa', alpha3: 'zaf', id: '710' },
    {
      name: 'South Georgia and the South Sandwich Islands',
      alpha3: 'sgs',
      id: '239'
    },
    { name: 'South Sudan', alpha3: 'ssd', id: '728' },
    { name: 'Spain', alpha3: 'esp', id: '724' },
    { name: 'Sri Lanka', alpha3: 'lka', id: '144' },
    { name: 'Sudan', alpha3: 'sdn', id: '729' },
    { name: 'Suriname', alpha3: 'sur', id: '740' },
    { name: 'Svalbard and Jan Mayen', alpha3: 'sjm', id: '744' },
    { name: 'Sweden', alpha3: 'swe', id: '752' },
    { name: 'Switzerland', alpha3: 'che', id: '756' },
    { name: 'Syrian Arab Republic', alpha3: 'syr', id: '760' },
    { name: 'Taiwan, Province of China', alpha3: 'twn', id: '158' },
    { name: 'Tajikistan', alpha3: 'tjk', id: '762' },
    { name: 'Tanzania, United Republic of', alpha3: 'tza', id: '834' },
    { name: 'Thailand', alpha3: 'tha', id: '764' },
    { name: 'Timor-Leste', alpha3: 'tls', id: '626' },
    { name: 'Togo', alpha3: 'tgo', id: '768' },
    { name: 'Tokelau', alpha3: 'tkl', id: '772' },
    { name: 'Tonga', alpha3: 'ton', id: '776' },
    { name: 'Trinidad and Tobago', alpha3: 'tto', id: '780' },
    { name: 'Tunisia', alpha3: 'tun', id: '788' },
    { name: 'Turkey', alpha3: 'tur', id: '792' },
    { name: 'Turkmenistan', alpha3: 'tkm', id: '795' },
    { name: 'Turks and Caicos Islands', alpha3: 'tca', id: '796' },
    { name: 'Tuvalu', alpha3: 'tuv', id: '798' },
    { name: 'Uganda', alpha3: 'uga', id: '800' },
    { name: 'Ukraine', alpha3: 'ukr', id: '804' },
    { name: 'United Arab Emirates', alpha3: 'are', id: '784' },
    {
      name: 'United Kingdom of Great Britain and Northern Ireland',
      alpha3: 'gbr',
      id: '826'
    },
    { name: 'United States of America', alpha3: 'usa', id: '840' },
    { name: 'United States Minor Outlying Islands', alpha3: 'umi', id: '581' },
    { name: 'Uruguay', alpha3: 'ury', id: '858' },
    { name: 'Uzbekistan', alpha3: 'uzb', id: '860' },
    { name: 'Vanuatu', alpha3: 'vut', id: '548' },
    { name: 'Venezuela (Bolivarian Republic of)', alpha3: 'ven', id: '862' },
    { name: 'Viet Nam', alpha3: 'vnm', id: '704' },
    { name: 'Virgin Islands (British)', alpha3: 'vgb', id: '092' },
    { name: 'Virgin Islands (U.S.)', alpha3: 'vir', id: '850' },
    { name: 'Wallis and Futuna', alpha3: 'wlf', id: '876' },
    { name: 'Western Sahara', alpha3: 'esh', id: '732' },
    { name: 'Yemen', alpha3: 'yem', id: '887' },
    { name: 'Zambia', alpha3: 'zmb', id: '894' },
    { name: 'Zimbabwe', alpha3: 'zwe', id: '716' },
    { name: 'Unknown', alpha3: 'zzz', id: '10000' }
  ]
}
