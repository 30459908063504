import React, { useState, useEffect } from 'react'
import ReportHeader from './ReportHeader'
import ReportOverview from './ReportOverview'
import ReportSeoStats from './ReportSeoStats'
import ReportImpressionMetrics from './ReportImpressionMetrics'
import ReportClickMetrics from './ReportClickMetrics'
import ReportTrafficSources from './ReportTrafficSources'
import ReportPageResult from './ReportPageResult'
import ReportPageNew from './ReportPageNew'
import ReportKeywordNew from './ReportKeywordNew'
import ReportKeywordResult from './ReportKeywordResult'
import ReportLoading from './ReportLoading'
import ReportError from './ReportError'
import appEnv from '../../appenv'

function ReportContent(props) {
  return (
    <div>
      {props.reportData.overview && (
        <ReportOverview reportOverview={props.reportData.overview} />
      )}
      <ReportSeoStats
        seoStatData={props.reportData.seoStatData}
        seoStatDataComparison={props.reportData.seoStatDataComparison}
        clickAndImpressionData={props.reportData.clickAndImpressionData}
        clickAndImpressionDataComparison={
          props.reportData.clickAndImpressionDataComparison
        }
        seoStatIndexedComparison={props.reportData.seoStatIndexedComparison}
        backlinkData={props.reportData.backlinkData}
        backlinkDataComparison={props.reportData.backlinkDataComparison}
        comparisonDays={props.reportData.dateData.comparisonDays}
        gaImportStartDate={props.reportData.gaImportStartDate}
      />
      <ReportImpressionMetrics
        clickAndImpressionData={props.reportData.clickAndImpressionData}
        comparisonDays={props.reportData.dateData.comparisonDays}
      />
      <ReportClickMetrics
        clickAndImpressionData={props.reportData.clickAndImpressionData}
        comparisonDays={props.reportData.dateData.comparisonDays}
      />
      <ReportTrafficSources trafficSources={props.reportData.trafficSources} />
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Page Metrics</h2>
      </div>
      <div className='group'>
        <ReportPageResult
          title={'Top Pages'}
          paginationRoute={'top'}
          gscWebPage={props.reportData.gscWebPageTop}
        />
        <ReportPageResult
          title={'Improved Pages'}
          paginationRoute={'improved'}
          gscWebPage={props.reportData.gscWebPageImproved}
        />
        <ReportPageResult
          title={'Declined Pages'}
          paginationRoute={'declined'}
          gscWebPage={props.reportData.gscWebPageDeclined}
        />
        <ReportPageNew gscWebPage={props.reportData.gscWebPageNew} />
      </div>
      <div className='reportSection'>
        <h2 className='reportSectionFont'>Keyword Metrics</h2>
      </div>
      <ReportKeywordResult
        title={'Top Keywords'}
        paginationRoute={'top'}
        gscWebKeyword={props.reportData.gscWebKeywordTop}
      />
      <ReportKeywordResult
        title={'Improved Keywords'}
        paginationRoute={'improved'}
        gscWebKeyword={props.reportData.gscWebKeywordImproved}
      />
      <ReportKeywordResult
        title={'Declined Keywords'}
        paginationRoute={'declined'}
        gscWebKeyword={props.reportData.gscWebKeywordDeclined}
      />
      <ReportKeywordNew gscWebKeyword={props.reportData.gscWebKeywordNew} />
    </div>
  )
}

function ReportMain(props) {
  return (
    <div className='reportBox'>
      <ReportHeader
        reportBranding={props.requestData.branding}
        reportName={props.requestData.siteUrl}
        reportStartDate={props.requestData.dateData.startDateHeading}
        reportEndDate={props.requestData.dateData.endDateHeading}
      />
      <ReportContent
        reportData={props.requestData}
        urlQueryString={window.location.search}
      />
    </div>
  )
}

export default function Report(props) {
  const [requestData, setRequestData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [hasRequestError, setHasRequestError] = useState(false)

  useEffect(() => {
    const reportIds = window.location.pathname.split('/')
    let reportDetailUrl =
      appEnv.apiHostname +
      '/v1/public/seo/report/' +
      reportIds[3] +
      '/' +
      reportIds[4] +
      window.location.search
    fetch(reportDetailUrl, {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET',
      mode: 'cors'
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            response.json().then((jsonResponse) => {
              if (jsonResponse.data) {
                setRequestData(jsonResponse.data)
                setIsLoading(false)
              } else {
                setHasRequestError(true)
                setIsLoading(false)
              }
            })
            break
          case 400:
            setHasRequestError(true)
            setIsLoading(false)
            break
          case 500:
            setHasRequestError(true)
            setIsLoading(false)
            break
          default:
            setHasRequestError(true)
            setIsLoading(false)
        }
      })
      .catch((err) => {
        setHasRequestError(true)
        setIsLoading(false)
      })
  }, [])

  return (
    <div>
      {isLoading ? (
        <ReportLoading />
      ) : hasRequestError ? (
        <ReportError pathName={window.location.pathname} />
      ) : (
        <ReportMain requestData={requestData} />
      )}
    </div>
  )
}
